import * as React from 'react';
import { Grid } from '@material-ui/core';
import CategoryLayout from '../components/CategoryLayout';
import Author from '../components/Author';
import { Autor } from '../models/Autor';
import { useAutorPosts } from '../operations/queries';

export default function Autores(): JSX.Element {
  const autores: Autor[] = useAutorPosts();

  let autores_new = autores.filter((autor)=>{
    return autor.nombre !== "Arne Severijns";
  });
  // console.log(autores_new)
  return (
    <CategoryLayout title="Autores" >
      <Grid container spacing={4} style={{ marginBottom: 20 }}>
        {autores &&
          autores_new.map(autor => {
            return (
              <Grid item xs={12} sm={12} md={6}>
                <Author authorObject={autor} colorText="black" />
              </Grid>
            );
          })}
      </Grid>
    </CategoryLayout>
  );
}
